import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import logo from "../../../../assets/Diwali_LandingPage/TopImage/logo.svg";
import magnify from "../../../../assets/Diwali_LandingPage/TopImage/magnify.svg";
import styles from "./MobileForm.module.css";

const MobileForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [captchaValid, setCaptchaValid] = useState(false);
  const [captchaError, setCaptchaError] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    preferredTime: "Between 9 AM and 12 PM",
    preferredDay: "Monday",
  });
  const [formSubmitError, setFormSubmitError] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const recaptchaRef = useRef(null);

  useEffect(() => {
    // Detect device type and screen size
    const userAgent = navigator.userAgent.toLowerCase();
    if (/android/i.test(userAgent)) {
      setDeviceType("android");
    } else if (/iphone|ipad|ipod/i.test(userAgent)) {
      setDeviceType("ios");
    } else {
      setDeviceType("desktop");
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCaptchaChange = async (captchaValue) => {
    setCaptchaValid(true);
    setCaptchaError("");
    try {
      const response = await fetch(
        "https://devapi.fabits.com/customerservice/api/captcha/verify",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ captchaValue }),
        }
      );

      const data = await response.json();
      setCaptchaValid(data.success);

      if (!data.success) {
        setCaptchaError("Captcha validation failed. Please try again.");
      }
    } catch (error) {
      console.error("Captcha validation failed:", error);
      setCaptchaValid(false);
      setCaptchaError("Captcha validation failed. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValid) {
      setCaptchaError("Please complete the reCAPTCHA verification!");
      setFormSubmitError("You must complete the CAPTCHA to proceed.");
      return;
    }

    setFormSubmitError("");

    const formattedFormData = {
      ...formData,
      phoneNumber: `+91${formData.phoneNumber}`,
    };

    try {
      const response = await fetch(
        "https://apimywealth.fabits.com/customerservice/api/financialReview",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formattedFormData),
        }
      );

      const responseText = await response.text();

      if (response.ok) {
        try {
          const responseData = JSON.parse(responseText);
          console.log("API Response:", responseData);
          setIsSubmitted(true);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          console.log("Raw response:", responseText);
          setIsSubmitted(true);
        }
      } else {
        console.error("Error submitting the form data", response.status);
        setIsSubmitted(false);
      }
    } catch (error) {
      console.error("API Error:", error);
      setIsSubmitted(true);
    }
  };

  const handleRedirect = () => {
    if (deviceType === "android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.fabits.mywealth&hl=en_IN";
    } else if (deviceType === "ios") {
      window.location.href =
        "https://apps.apple.com/ca/app/fabits/id1600230367";
    } else {
      window.location.href = "https://mywealth.fabits.com/sign-up";
    }
  };

  return (
    <div
      className={styles.formContainer}
      style={isMobile ? { width: "100%" } : {}}
    >
      {!isSubmitted ? (
        <form className={styles.form} id="formSection" onSubmit={handleSubmit}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
            <img className={styles.mag} src={magnify} alt="magnify" />
          </div>
          <h3>Get your finances reviewed for free</h3>
          <label>Full name</label>
          <input
            type="text"
            placeholder="Dhruv Jain"
            required
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
          />
          <label>Email address</label>
          <input
            type="email"
            placeholder="dhruv.jain@email.com"
            required
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <label>Phone number</label>
          <input
            type="tel"
            placeholder="+91"
            required
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />

          <div className={styles.contime}>
            <div>
              <label>Preferred Time</label>
              <select
                required
                name="preferredTime"
                value={formData.preferredTime}
                onChange={handleChange}
              >
                <option>Between 9 AM and 12 PM</option>
                <option>Between 12 PM and 5 PM</option>
                <option>Between 5 PM and 10 PM</option>
              </select>
            </div>

            <div>
              <label>Preferred Day</label>
              <select
                required
                name="preferredDay"
                value={formData.preferredDay}
                onChange={handleChange}
              >
                <option>Monday</option>
                <option>Tuesday</option>
                <option>Wednesday</option>
                <option>Thursday</option>
                <option>Friday</option>
                <option>Saturday</option>
                <option>Sunday</option>
              </select>
            </div>
          </div>

          {formSubmitError && (
            <div className={styles.errorMessage}>{formSubmitError}</div>
          )}

          <div style={{ marginTop: "1rem", width: "90%" }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdJW-spAAAAANJ9T03r-2mv0bOy1dTL05_Wm3rh"
              onChange={handleCaptchaChange}
            />
          </div>

          {captchaError && (
            <div className={styles.errorMessage}>{captchaError}</div>
          )}

          <button type="submit" disabled={!captchaValid}>
            {"Get Started"}
          </button>
        </form>
      ) : (
        <div className={styles.thankYouMessage}>
          <h3>Thank you!</h3>
          <p>We’ll be in touch shortly. In the meantime,</p>
          <button className={styles.appButton} onClick={handleRedirect}>
            {deviceType === "android"
              ? "Get on Google Play"
              : deviceType === "ios"
              ? "Download on App Store"
              : "Sign Up"}
          </button>
        </div>
      )}
    </div>
  );
};

export default MobileForm;
