import React from "react";
import styles from "./Works.module.css";

const Works = () => {
  const cardsContent = [
    {
      index: 1,
      title: "Financial Wellness Review",
      content:
        "Meet with a Personal Finance Assistant to review your financial wellness.",
    },
    {
      index: 2,
      title: "Budgeting & Planning",
      content:
        "Get assistance in creating a budgeting plan to achieve your financial goals.",
    },
    {
      index: 3,
      title: "Investment Strategy",
      content:
        "Learn how to optimize your investment portfolio for better returns.",
    },
  ];

  return (
    <div className={styles.Contain}>
      <div>
        <p>How it works:</p>
      </div>
      <div className={styles.cardsContainer}>
        {cardsContent.map((card, index) => (
          <div key={index} className={styles.cards}>
            <div className={styles.imageStyle}>
              <p>{card.index}</p>
            </div>
            <div className={styles.para}>
              <p className={styles.top}>{card.title}</p>
              <p className={styles.cont}>{card.content}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.freecall}>
        <a href="#formSection">
          <button className={styles.btn}>Get a free financial review</button>
        </a>
      </div>
    </div>
  );
};

export default Works;
