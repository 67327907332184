import React from "react";
import styles from "../Diwali/ReviewFinance.module.css";
import TopSection from "./TopSection/TopSection";
import Testimony from "./Testimonials/Testimony";
import Financial from "./FinancialReview/Financial";
import Works from "./It_works/Works";
import Review from "./Review/Review";
import WhatFabit from "./WhatFabits/WhatFabit";
import FAQ from "./FAQ/FAQ";
import useMediaQuery from "../../../hooks/useMediaQuery";
import MobileForm from "./MobileForm/MobileForm";

const Finance = () => {
 const isMobile = useMediaQuery("(max-width: 767px)");
  return (
    <>
      <div className={styles.container}>
        <TopSection />
        {isMobile && <MobileForm/>}
        <Financial />
        <div className={styles.testimony}>
          <Testimony />
        </div>
        <div className={styles.content}></div>
        <Works />
        <Review />
        <WhatFabit />
        <FAQ />
        {/* <Message/> */}
        {/* <Barfix/>  */}

        {/* <iframe src="https://outlook.office365.com/owa/calendar/DiwaliFinancialWellnessSession@fabits.com/bookings/"
          width="100%" height="100%"  style={{ border: 0 }} title='Book your financial wellness review' />

        <button onclick="window.open('https://outlook.office365.com/owa/calendar/DiwaliFinancialWellnessSession@fabits.com/bookings/', '_self');">
          Open in Full Page
        </button> */}
      </div>
    </>
  );
};

export default Finance;
